<template>
    <div class="mezzanines">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Mezzanines y Entrepisos',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabricantes de estantería para almacenamiento manual' },
			{ name: 'keywords', content:'estantería metálica, mezzanines, entrepisos modulares' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Mezzanines y Entrepisos'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabricantes de mezzanines y entrepisos modulares'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Mezzanines y Entrepisos'},
			{name: 'twitter:description', content: 'Fabricantes de mezzanines y entrepisos modulares' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Mezzanines y Entrepisos'},
			{itemprop: 'description', content: 'Fabricantes de mezzanines y entrepisos modulares' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: 'Entrepisos y Mezzanines',
                types: [
                    // { text: 'Todos', value: 'todos' },
                    { text: 'Mezzanines', value: 'mezzanines' },
                    { text: 'Entrepisos', value: 'estanteria-con-entrepisos' }
                ],
                products: [
                    {
                        name: 'Mezzanines',
                        description: `
                            <p>
                                Te permiten aprovechar al máximo la altura de un local duplicando o triplicando su superficie y adecuarla como zona de almacenamiento. 
                                Este sistema es la mejor solución para aprovechar el espacio disponible. Los mezzanines son totalmente desmontables y reutilizables, 
                                siendo muy sencillo modificar su estructura, dimensiones o emplazamiento. Se puede adaptar a las necesidades concretas de cada cliente 
                                y se pueden complementar con otros sistemas de estanterías existentes.
                            </p>
                        `,
                        url_image: 'estanterias-metalicas/entrepisos/productos/mezzanines/mezzanine_1.jpg',
                        slides: [
                            'estanterias-metalicas/entrepisos/productos/mezzanines/mezzanine_1.jpg'
                        ],
                        price: '',
                        tags: ['mezzanines']
                    },
                    {
                        name: 'Estantería con entrepisos',
                        description: `
                            <p>
                                La estantería con entrepisos te proporciona un excelente aprovechamiento en altura, a través de la creación de diferentes niveles 
                                de pasillos de carga manual, alturas a las que se accede a través de escaleras. Este sistema permite el máximo aprovechamiento vertical, 
                                sin necesidad de la utilización de montacargas.
                            </p>
                        `,
                        url_image: 'estanterias-metalicas/entrepisos/productos/estanteria-con-entrepisos/entrepisos_1.jpg',
                        slides: [
                            'estanterias-metalicas/entrepisos/productos/estanteria-con-entrepisos/entrepisos_1.jpg',
                            'estanterias-metalicas/entrepisos/productos/estanteria-con-entrepisos/entrepisos_2.jpg'
                        ],
                        price: '',
                        tags: ['estanteria-con-entrepisos']
                    }
                ],
            }
        }
    }
}
</script>